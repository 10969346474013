import {makeStyles} from '@material-ui/core';
import Axios from 'axios';
import MaterialTable, {MTableToolbar} from 'material-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {calculateReadingValues} from '../../utils/calculateReadingValues';
import {tableIcons} from '../../styles/tableIcons';

const useStyles = makeStyles(({palette}) => ({
  toolbar: {
    paddingRight: '8px',
    textTransform: 'capitalize',
    color: 'white',
    background: palette.meterTypeColor,
    '& svg': {
      color: 'white',
    },
  },
}));

export const DashboardUserMeterReadingsTable = ({meter, user, setMeter}) => {
  const classes = useStyles(meter);

  const tableReadings = meter.readings?.filter((reading) => {
    return reading.deleted !== true;
  });

  const isCalculatedFromValue =
    meter.dataSource === 'meter' ||
    meter.dataSource === 'audit' ||
    meter.dataSource === '' ||
    !meter.dataSource;

  const columns = [
    {
      title: 'Date',
      field: 'time',
      type: 'date',
      render: (rowData) => moment(rowData.time).format('LL'),
      defaultSort: 'desc',
    },
    {
      title: 'Time',
      field: 'time',
      type: 'time',
      render: (rowData) => moment(rowData.time).format('hh:mm A'),
    },
    {
      title: 'Value',
      field: 'value',
      type: 'numeric',
      editable: isCalculatedFromValue ? 'always' : 'never',
      render: (rowData) =>
        rowData
          ? rowData.value
            ? Number.parseFloat(rowData.value).toFixed(2)
            : 'N/A'
          : 0,
    },
    {
      title: 'Consumption', // same as "Raw Usage"
      field: 'rawUsage',
      type: 'numeric',
      editable: !isCalculatedFromValue ? 'always' : 'never',
      render: (rowData) =>
        rowData ? (rowData.rawUsage ? rowData.rawUsage.toFixed(2) : 'N/A') : '',
    },
  ];

  const isMeterOwner = meter.user === user._id;

  const handleTableChange = {
    onRowAdd: isMeterOwner
      ? async (newData) => {
          let meterCopy = {...meter}; // copy meter
          let newReading = {...newData};
          meterCopy.readings.push(newReading);
          meterCopy = calculateReadingValues(meterCopy);
          try {
            const dbResponse = await Axios.post(
              `/api/meter/${meterCopy._id}`,
              meterCopy,
            );
            setMeter(calculateReadingValues(dbResponse.data));
          } catch (err) {
            console.error('Error creating new readings', err);
          }
        }
      : null,
    onRowUpdate: isMeterOwner
      ? async (newData, oldData) => {
          let meterCopy = {...meter}; // copy meter
          let index = meterCopy.readings.indexOf(oldData);
          let updatedReading = {...newData};
          meterCopy.readings[index] = updatedReading;
          meterCopy = calculateReadingValues(meterCopy);
          try {
            const dbResponse = await Axios.post(
              `/api/meter/${meterCopy._id}`,
              meterCopy,
            );
            setMeter(calculateReadingValues(dbResponse.data));
          } catch (err) {
            console.error('Error updating reading', err);
          }
        }
      : null,
    onRowDelete: isMeterOwner
      ? async (oldData) => {
          let meterCopy = {...meter}; // copy meter
          let index = meterCopy.readings.indexOf(oldData);
          const softDeletedReading = {
            ...oldData,
            deleted: true,
          };
          meterCopy.readings[index] = softDeletedReading;
          meterCopy = calculateReadingValues(meterCopy);
          try {
            const dbResponse = await Axios.post(
              `/api/meter/${meter._id}`,
              meterCopy,
            );
            setMeter(calculateReadingValues(dbResponse.data));
          } catch (error) {
            console.error(error);
          }
        }
      : null,
  };

  return (
    <MaterialTable
      title={`${meter.type} Data Table`}
      data={tableReadings}
      components={{
        Toolbar: (props) => (
          <div className={classes.toolbar}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      options={{
        search: false,
        paging: false,
        minBodyHeight: '400px',
        pageSize: 5,
        actionsColumnIndex: -1,
        addRowPosition: 'first',
        actionsCellStyle: {
          paddingLeft: '20px',
        },
      }}
      columns={columns}
      editable={handleTableChange}
      icons={tableIcons}
    />
  );
};

DashboardUserMeterReadingsTable.prototype = {
  user: PropTypes.object.isRequired,
  meter: PropTypes.object.isRequired,
  setMeter: PropTypes.func,
};
